// import React from "react";
// import axios from "axios";
import { removeStorage } from "./localStorageHelpers";
import { KTCookie as Cookie } from "../assets/cookie";
// import { GET_COUNTRY_LIST } from "../app/Endpoints";

const { REACT_APP_URL, REACT_APP_VERSION, REACT_APP_IDENTIFIER } = process.env;

export const tabletWidth = 768;
export const mobileLargeWidth = 425;
export const RESIZE_DELAY_TIMEOUT = 200;
export const DEFAULT_DEBOUNCE_TIMEOUT = 500;

export const urlReplacer = async (replace, url) => {
  let newUrl = url;

  const replaceVaraiable = (keys) => {
    keys.map((key) => {
      newUrl = newUrl.replace(`{${key}}`, replace[key]);
      return key;
    });
  };

  if (replace instanceof Object) {
    await replaceVaraiable(Object.keys(replace));
    return newUrl;
  }

  return url;
};

// export const fetchCountry = async () => {
//   const res = await axios.get(GET_COUNTRY_LIST);
//   return res;
// };

export const setConsoleMessage = () => {
  const { REACT_APP_ENV } = process.env;

  if (REACT_APP_ENV !== "production") {
    console.log(
      `%c ${
        REACT_APP_ENV ? REACT_APP_ENV.toString().toUpperCase() : "UNKNOWN"
      }  Enviroment`,
      "color: red; font-weight:bolder; font-size: 36px; text-shadow: 1px 1px 0px black, -1px 1px 0px black, 1px -1px 0px black, -1px -1px 0px black; line-height: 2; font-family: arial"
    );
  } else {
    // generate from "http://patorjk.com/software/taag/"
    console.log(
      "%c\n" +
        "███╗   ██╗██╗██████╗ ██████╗  ██████╗ ███╗   ██╗    ██████╗  █████╗ ██╗███╗   ██╗████████╗\n" +
        "████╗  ██║██║██╔══██╗██╔══██╗██╔═══██╗████╗  ██║    ██╔══██╗██╔══██╗██║████╗  ██║╚══██╔══╝\n" +
        "██╔██╗ ██║██║██████╔╝██████╔╝██║   ██║██╔██╗ ██║    ██████╔╝███████║██║██╔██╗ ██║   ██║\n" +
        "██║╚██╗██║██║██╔═══╝ ██╔═══╝ ██║   ██║██║╚██╗██║    ██╔═══╝ ██╔══██║██║██║╚██╗██║   ██║\n" +
        "██║ ╚████║██║██║     ██║     ╚██████╔╝██║ ╚████║    ██║     ██║  ██║██║██║ ╚████║   ██║\n" +
        "╚═╝  ╚═══╝╚═╝╚═╝     ╚═╝      ╚═════╝ ╚═╝  ╚═══╝    ╚═╝     ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝   ╚═╝",
      " color : #da2442;"
    );

    console.log(
      "%cWARNING!",
      "color: red; font-weight:bolder; font-size: 36px; text-shadow: 1px 1px 0px black, -1px 1px 0px black, 1px -1px 0px black, -1px -1px 0px black; line-height: 2; font-family: arial"
    );
    console.log(
      "%cThis is a browser feature intended for developers. If you facing any error please contact our developer team at :",
      "font-size: large; font-family: arial"
    );
    console.log(
      "%cadmin-php@nipponpaint.com.my",
      " color : Blue; font-size: large; font-family: arial"
    );
    console.log(
      "%c(Please attach error screenshots in the email)",
      " font-size: medium; font-style: italic; font-family: arial"
    );
  }
};

export const assetUrl = (path, version = null) =>
  `${REACT_APP_URL + path}?v=${version ?? REACT_APP_VERSION}`;

export const clearLocalStorage = () => {
  // this will clear everything except for redux persist ydata

  const keysToclear = [
    process.env.REACT_APP_I18N_CONFIG_KEY,
    process.env.REACT_APP_LANGUAGE_DROPDOWN,
  ];

  keysToclear.map((key) => removeStorage(key));
};

export const delay = (ms = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const range = (from, to, step = 1) => {
  let i = from;
  const rangeH = [];

  while (i <= to) {
    rangeH.push(i);
    i += step;
  }

  return rangeH;
};

export const filterItems = (needle, heystack, key) => {
  // const query = needle.toLowerCase();
  // return heystack.filter((item) => {
  //   if (key) {
  //     return item[key].toLowerCase().indexOf(query) >= 0;
  //   }
  //   return item.toLowerCase().indexOf(query) >= 0;
  // });

  const query = needle;
  return heystack.filter((item) => {
    if (key) return item[key] === query;
    return item === query;
  });
};

export const getFirstParentMatch = (el, selector) => {
  let element = el;
  if (!element) {
    return element;
  }
  // eslint-disable-next-line no-unused-expressions
  element.matches(selector) ||
    (element =
      element.nodeName.toLowerCase() === "html"
        ? false
        : getFirstParentMatch(element.parentNode, selector));
  return element;
};

export const numberType = (filter, t) => {
  switch (filter) {
    case t("No"):
      return 0;
    case t("Yes"):
      return 1;
    default:
      return 0;
  }
};

export const tableDefaultHandleFilter = (loadedData, filter) => {
  const newData = loadedData.filter((rec) => {
    const checker = [];
    const keys = Object.keys(filter);

    keys.forEach((key) => {
      const value = rec[key];
      const searchValuesObj = filter[key];
      // const searchValues = [];
      const { length } = searchValuesObj;
      // searchValuesObj.forEach((obj) => searchValues.push(obj.title));
      if (length === 0) checker.push(true);

      for (let i = 0; i < length; i++) {
        const result = filterItems(searchValuesObj[i].title, [value]);

        if (result.length > 0) {
          checker.push(true);
          i = length;
        } else if (i === length - 1) {
          checker.push(false);
        }
      }
    });

    if (checker.includes(false)) return false;

    return true;
  });

  return newData;
};

// export const renderHelperTextError = (formik, fieldname) => {
//   if (formik.touched[fieldname] && !!formik.errors[fieldname]) {
//     return <FormHelperText error>{formik.errors[fieldname]}</FormHelperText>;
//   }
//   return null;
// };

export const SortTypes = {
  ASC: "ASC",
  DESC: "DESC",
};

export const commaSeparators = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const handleResponse = ({ response, success = null, fail = null }) => {
  if (!response) {
    console.error("response is required");
    return response;
  }

  if (response.succeeded) {
    if (typeof success === "function") return success(response);
    return null;
  }

  if (typeof fail === "function") return fail(response);
  return null;
};

export const conditionChecker = (sign, cond, value) => {
  switch (sign) {
    case ">":
      console.log("value > cond;", value > cond);
      return value > cond;
    case "<":
      return value < cond;
    case ">=":
      return value >= cond;
    case "<=":
      return value <= cond;
    default:
      return false;
  }
};
export const lowerCase = (string) => string.toLowerCase();

export const rand = (length = 6) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getUniqueKeyCookieValue = () =>
  Cookie.getCookie(`${REACT_APP_IDENTIFIER}_ukey`) ?? null;

export const setUniqueKeyCookie = () => {
  if (!getUniqueKeyCookieValue()) {
    Cookie.setCookie(`${REACT_APP_IDENTIFIER}_ukey`, rand(40), {
      expires: new Date(9999, 12, 31),
    });
  }
};
