import axios from "axios";
import { defaultDialogOpt, swDialogBox } from "./dialogHelper";
import serviceConfig from "../app/ServiceConfig";
import { refreshToken } from "../app/Auth/services/AuthCrud";
import { actions } from "../app/Auth/redux/authRedux";
import store from "../redux/store";

const opt = defaultDialogOpt;

const { REACT_APP_SERVICES_URL, REACT_APP_SERVICES_TOKEN } = process.env;

const setUrl = (config, countryCode = null) => {
  const { auth } = store.getState();

  const explode = config.url.split("/");

  // check prefix slash(/) exist
  const hasSlash = explode[0] === "";

  // check config for service exists
  const service = hasSlash ? explode[1] : explode[0];
  const serviceConfigExists = service in serviceConfig;

  // get service version if config exists
  const version = serviceConfigExists
    ? `${serviceConfig[service].version}/`
    : "";

  // set base url for the request
  let baseUrl = config.baseURL;
  if (!baseUrl) {
    baseUrl =
      serviceConfigExists && serviceConfig[service].url
        ? serviceConfig[service].url
        : REACT_APP_SERVICES_URL;
  }
  baseUrl += "/";

  // get country code set as the 1st part of uri
  let country = countryCode ?? null;

  country =
    serviceConfigExists && auth && auth.selectedCountry
      ? `${auth.selectedCountry}/`
      : `${country ? `${country}/` : ""}`;

  // get uri and remomve slash if exists
  const uri = hasSlash ? config.url.substr(1) : config.url;

  // final URL : baseURL + country(optional) +  version(optional) + uri
  return `${baseUrl}${country}${version}${uri}`;
};

const updateHeaderForRecursive = (config, token = null) => {
  if (token) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${token}`,
    });
  }

  Object.assign(config, {
    skipInterceptor: {
      request: true,
    },
  });

  return config;
};

const setHeader = (headers) => {
  const { auth } = store.getState();
  const defaultHeader = { "api-token": REACT_APP_SERVICES_TOKEN };

  if (auth && auth.authToken)
    defaultHeader.Authorization = `Bearer ${auth.authToken}`;

  Object.assign(headers, defaultHeader);

  return headers;
};

const handleRefreshToken = async (config) => {
  const { auth } = store.getState();

  store.dispatch(actions.isRefreshing(true));

  const res = await refreshToken(auth.refreshToken);

  if (res?.succeeded) {
    const { token, refresh_token, refresh_token_expiry } = res.objects;

    store.dispatch(actions.refresh(token, refresh_token, refresh_token_expiry));

    Object.assign(config.headers, {
      Authorization: `Bearer ${token}`,
    });

    Object.assign(config, {
      skipInterceptor: {
        request: true,
      },
    });

    const data = await axios(config);

    return data;
  }

  // return new Promise((resolve, reject) => { reject(Error('refresh tokey failed')); });
  return undefined;
};

const handleStatus401 = async (data, config) => {
  if (data?.code !== "-2") {
    swDialogBox({
      ...opt,
      title: null,
      // icon: null,
      text: "Logged session ended.",
      closeOnClickOutside: false,
      closeOnEsc: false,
      button: "Re-Login",
      dangerMode: true,
    }).then((retry) => {
      if (retry) {
        const currentPathname = window.location.pathname || "";

        // Only save the location if is not login page, to prevent infinite loop
        if (currentPathname.indexOf("auth") === -1)
          store.dispatch(actions.lastLocation(currentPathname));

        window.location = "/logout";
      }
    });
    return data;
  }

  const {
    auth: { isRefreshing },
  } = store.getState();

  if (!isRefreshing) {
    const newData = await handleRefreshToken(config);

    store.dispatch(actions.isRefreshing(false));

    return newData;
  }

  return new Promise((resolve) => {
    const intervalId = setInterval(async () => {
      // eslint-disable-next-line no-shadow
      const {
        auth: { isRefreshing: isRefreshings, authToken },
      } = store.getState();

      if (!isRefreshings) {
        clearInterval(intervalId);
        const newConfig = updateHeaderForRecursive(config, authToken);

        const resp = await axios(newConfig);
        resolve(resp);
      }
    }, 100);
  });
};

const handleStatus422 = (data, config) => {
  let errorMessage = "";
  const { objects, message } = data || {};

  Object.keys(objects).forEach((key) => {
    errorMessage += `${objects?.[key]?.[0]} \n`;
  });

  if (!config?.skipGlobalHandling?.[422])
    swDialogBox({ ...opt, title: message, text: `${errorMessage}` });

  return data;
};

const handleStatus = (response, config) => {
  const { data, status, headers } = response;

  const errMsg = headers["error-message"] ?? "Unknwon Error";

  switch (status) {
    // should not have this status
    case 200:
      if (data instanceof Blob) return data;
      if (!data?.succeeded && !config?.skipGlobalHandling?.[200])
        swDialogBox({
          ...opt,
          text: `Something went wrong. \n Error : ${data?.message}`,
        });
      return data;
    case 422:
      return handleStatus422(data, config);
    case 401:
      return handleStatus401(data, config);
    case 404:
      swDialogBox({
        ...opt,
        text: `Page or resources that you looking not found. \n Error : ${data?.message}`,
      });
      return data;
    case 500:
      swDialogBox({
        ...opt,
        text: `Something went wrong. \n Error : ${errMsg}`,
      });
      throw errMsg;
    default:
      swDialogBox({ ...opt, text: "Something went wrong. Please try again." });
      return data;
  }
};

const handleBlob = (headers, data) => {
  if (typeof headers["content-disposition"] === "undefined") return;

  const fileName = headers["content-disposition"]
    .split("filename=")[1]
    .split(";")[0];

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function setupAxios() {
  axios.interceptors.request.use(
    (config) => {
      // skip interceptor if config in send from axios method
      if (config.skipInterceptor?.request) return config;

      Object.assign(config, {
        // eslint-disable-next-line camelcase
        url: setUrl(config, config.data?.country_code),
        headers: setHeader(config.headers),
      });

      return config;
    },
    (err) => {
      Promise.reject(err);
    }
  );

  const responseSuccess = (res) => {
    // skip interceptor if config in send from axios method
    if (res.config?.skipInterceptor?.response) return res;

    const { config, data, headers } = res;

    if (config.responseType === "blob") handleBlob(headers, data);

    if (
      process.env.REACT_APP_ENV !== "production" ||
      process.env.REACT_APP_ENV !== "PRODUCTION"
    ) {
      console.log(`ApiRes ([${config.method}]${config.url}):`, res);
    }

    return handleStatus(res, config);
  };

  const responseFailed = (failedParams) => {
    if (axios.isCancel(failedParams)) {
      return {};
    }

    const { response, config, message } = failedParams;
    if (!response) {
      swDialogBox({
        ...opt,
        text: `Something went wrong. \n Error : ${message}`,
      });
      return {};
    }

    return handleStatus(response, config);
  };

  axios.interceptors.response.use(responseSuccess, responseFailed);
}
