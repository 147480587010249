import React, { useState } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextField } from "../../../General/_core/components";
import { VisibilityIconButton } from "./components";

const PasswordField = () => {
  const { t } = useTranslation();
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
  } = useController({ name: "password" });

  const [showPassword, setShowPassword] = useState(false);

  const errorMessage = error?.message;

  return (
    <TextField
      {...fieldProps}
      inputRef={ref}
      label={t("Password")}
      type={showPassword ? "text" : "password"}
      error={!!errorMessage}
      helperText={!!errorMessage && errorMessage}
      fullWidth
      style={{ fontSize: "13px", marginBottom: "1em" }}
      InputProps={{
        endAdornment: (
          <VisibilityIconButton
            visible={showPassword}
            onToggle={() => setShowPassword(!showPassword)}
          />
        ),
      }}
    />
  );
};

export default PasswordField;
