import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { assetUrl } from "../../../helpers";
import { postLogin } from "../services/AuthCrud";
import { actions } from "../redux/authRedux";
import { defaultDialogOpt, swDialogBox } from "../../../helpers/dialogHelper";
import { delay } from "../../../helpers/generalHelper";
import {
  CountrySelect,
  UsernameField,
  PasswordField,
  SignInButton,
} from "../components";

const Login = () => {
  const { REACT_APP_VERSION } = process.env;
  const { selectedCountry } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = {
    country_code: selectedCountry ?? "",
    username: "",
    password: "",
  };

  const requiredFieldMsg = t("Required field");

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(requiredFieldMsg),
    country_code: Yup.string().required(requiredFieldMsg),
    password: Yup.string().required(requiredFieldMsg),
  });

  const methods = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(LoginSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    await delay(1000);

    try {
      const response = await postLogin(
        values.username,
        values.password,
        values.country_code
      );

      const { succeeded, message, objects } = response || {};
      const {
        token,
        user,
        allowedSystem,
        refresh_token,
        refresh_token_expiry,
      } = objects || {};

      if (succeeded) {
        dispatch(
          actions.login(
            token,
            values.country_code,
            user,
            refresh_token,
            refresh_token_expiry,
            allowedSystem
          )
        );
      } else swDialogBox({ ...defaultDialogOpt, text: message });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="wrapper justify-content-center align-items-center">
          <div className="colm-form">
            <div className="colm-logo">
              <img
                src={assetUrl("/media/login/connect-logo.svg")}
                alt="Connect Logo"
                width="250"
              />
              <h2>Welcome back!</h2>
            </div>

            <div className="form-middle">
              <CountrySelect />
              <UsernameField />
              <PasswordField />
              <div className="btn-login-container">
                {/* <span className="mas">{t("Sign In")}</span> */}
                <SignInButton />
              </div>
            </div>
          </div>

          <div
            className="footer-contents"
            style={{ position: "absolute", bottom: "0" }}
          >
            <small>2022 &copy; Nippon Paint (M) Sdn Bhd</small>
            <span className="mx-2">|</span>
            <span>{` v${REACT_APP_VERSION}`}</span>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Login;
