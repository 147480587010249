import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { assetUrl } from "../../../helpers";
import { generateSystemToken } from "../../Auth/services/AuthCrud";

const Main = () => {
  const { t } = useTranslation();
  const { REACT_APP_VERSION } = process.env;
  const { allowedSystem, selectedCountry } = useSelector((state) => state.auth);

  const noPropagation = (e) => e.stopPropagation();

  const getSystemToken = async (code, url) => {
    const response = await generateSystemToken(code);

    let newurl = "";
    if (response?.succeeded) {
      const {
        objects: { token },
      } = response;
      newurl = `${url}?gt=${token}&country=${selectedCountry}`;
    }
    window.location.href = newurl;
  };

  return (
    <div className="wrapper">
      <div className="header">
        <img
          src={assetUrl("/media/login/connect-logo.svg")}
          alt="Connect Logo"
        />
        <a href="/logout">
          <Button className="logout">
            <img src={assetUrl("/media/login/logout.svg")} alt="Login" />
          </Button>
        </a>
      </div>
      <div className="container-fluid">
        <div className="content">
          <div className="flex-container">
            {allowedSystem.map((system) => {
              return (
                <a
                  onClick={() => getSystemToken(system.code, system.url)}
                  rel="noreferrer"
                  href="#"
                >
                  <div key={system.code} className="flex-item">
                    <div className="img-app">
                      <img
                        className="img-size"
                        src={system.image}
                        alt={system.code}
                      />
                    </div>
                    <div className="title-container">
                      <div className="big-title">{system.name}</div>
                      <div className="small-title">{system.description}</div>
                    </div>
                    {/* <div className="img-left"> */}
                    <div className="app-logo-container">
                      {system.ios !== null ||
                      system.android !== null ||
                      system.huawei !== null ? (
                        <span
                          style={{
                            fontWeight: "normal",
                            fontSize: 14,
                            color: "#555",
                            textAlign: "left",
                            width: "auto",
                          }}
                          className="visible"
                        >
                          {t("Download app:")}
                        </span>
                      ) : (
                        <span />
                      )}
                      <div className="logo-app">
                        {system.ios !== null ? (
                          <a
                            href={system.ios}
                            target="_blank"
                            rel="noreferrer"
                            onClick={noPropagation}
                          >
                            <img
                              src={assetUrl("/media/image/applestore.svg")}
                              alt="apple"
                            />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="logo-app">
                        {system.android !== null ? (
                          <a
                            href={system.android}
                            target="_blank"
                            rel="noreferrer"
                            onClick={noPropagation}
                          >
                            <img
                              src={assetUrl("/media/image/playstore.svg")}
                              alt="playstore"
                            />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="logo-app">
                        {system.huawei !== null ? (
                          <a
                            href={system.huawei}
                            target="_blank"
                            rel="noreferrer"
                            onClick={noPropagation}
                          >
                            <img
                              src={assetUrl("/media/image/huawei.svg")}
                              alt="huawei"
                            />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className="footer-contents">
        <small>2022 &copy; Nippon Paint (M) Sdn Bhd</small>
        <span className="mx-2">|</span>
        <span>{` v${REACT_APP_VERSION}`}</span>
      </div>
    </div>
  );
};

export default Main;
