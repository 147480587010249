import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";

const SelectOptions = forwardRef(
  ({ options, keyConfig, getOptionProps, ...props }, ref) => {
    const { label: labelKey, value: valueKey } = keyConfig;

    return (
      <Select ref={ref} {...props}>
        {options.map((...args) => {
          const [item] = args;

          return (
            <MenuItem
              key={item[valueKey]}
              value={item[valueKey]}
              {...getOptionProps(...args)}
            >
              {item[labelKey]}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
);

SelectOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  keyConfig: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  getOptionProps: PropTypes.func,
};

SelectOptions.defaultProps = {
  options: [],
  keyConfig: {
    label: "label",
    value: "value",
  },
  getOptionProps: () => {},
};

export default SelectOptions;
