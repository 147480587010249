// import axios from "axios";
// import { GET_COUNTRY_LIST } from "../../../Endpoints";

import { delay } from "../../../helpers/generalHelper";

export const fetchCountry = async () => {
  await delay();
  // const res = await axios.get(GET_COUNTRY_LIST);
  // return res;

  // FIXME: This is for testing purpose, please code below during development and enable code above
  return Promise.resolve({
    succeeded: true,
    code: 0,
    message: "",
    objects: {
      country: [
        {
          country_code: "MY",
          description: "Malaysia",
          currency_code: "RM",
          timezone: "+08:00",
        },
        {
          country_code: "ID",
          description: "Indonesia",
          currency_code: "IDR",
          timezone: "+07:00",
        },
        {
          country_code: "PH",
          description: "Philippines",
          currency_code: "PHP",
          timezone: "+08:00",
        },
        {
          country_code: "PK",
          description: "Pakistan",
          currency_code: "",
          timezone: "+05:00",
        },
        {
          country_code: "BD",
          description: "Bangladesh",
          currency_code: "BDT",
          timezone: "+06:00",
        },
      ],
    },
  });
};

export const getCustomerOptions = async () => {
  // const res = await axios.get(GET_COUNTRY_LIST);
  // return res;

  await delay();

  // FIXME: This is for testing purpose, please code below during development and enable code above
  return Promise.resolve({
    succeeded: true,
    code: 0,
    message: "",
    objects: {
      records: [
        {
          id: "B5E48F2E516223",
          kunnr: null,
          kunn2: null,
          name1: "A & A Architects Sdn Bhd (formerly A & A Rekarancang) (ACT)",
          name2: "",
          status: 4,
          street:
            "B78, Block B, Megan Avenue 1, 189, Jalan Tun Razak - KUALA LUMPUR",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E521E46",
          kunnr: null,
          kunn2: null,
          name1: "A Sani & Associates Sdn Bhd  Selangor (ACT)",
          name2: "",
          status: 4,
          street: "55A, Jalan SS22/19, Damansara Jaya - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E52632E",
          kunnr: null,
          kunn2: null,
          name1: "A+I Design (ACT)",
          name2: "",
          status: 4,
          street:
            "4321, Al House, Jalan Puteri 4/1, Boulevard 1, Bandar Puteri - PUCHONG",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E528F2B",
          kunnr: null,
          kunn2: null,
          name1: "ABA Jurutera Perunding (BCI)",
          name2: null,
          status: 1,
          street: null,
          created_from_source: "BCI",
        },
        {
          id: "B5E48F2E52BD8E",
          kunnr: null,
          kunn2: null,
          name1: "Abadi Piling Sdn Bhd (ACT)",
          name2: "",
          status: 4,
          street: "Wisma LPC, B333, Jalan Pahang - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E53008A",
          kunnr: null,
          kunn2: null,
          name1: "ABS Civil Consultant (ACT)",
          name2: "",
          status: 4,
          street:
            "185, Tingkat 1, Jalan BPJ 1/2, Seksyen 1, Bandar Puteri Jaya - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E5320BA",
          kunnr: null,
          kunn2: null,
          name1: "AC Haimi Jurutera Perunding (ACT)",
          name2: "",
          status: 4,
          street:
            "610, 6th Floor, Block A, Kelana Square, No.17, Jalan SS 7/26, Kelana Jaya - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E533D02",
          kunnr: null,
          kunn2: null,
          name1: "Advancecon Infra Sdn Bhd (formerly Advancecon Sdn Bhd) (ACT)",
          name2: "",
          status: 4,
          street:
            "16 &amp;18, Jalan Pekaka 8/3, Section 8, Kota Damansara - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E539DBF",
          kunnr: null,
          kunn2: null,
          name1: "AES Consult (ACT)",
          name2: "",
          status: 4,
          street:
            "34A, Taman Kota Laksamana, Jalan TLK 6, Taman Kota Laksamana - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E54128F",
          kunnr: null,
          kunn2: null,
          name1: "Ahmad Zaki Resources Berhad (AZRB) (ACT)",
          name2: "",
          status: 4,
          street: "Menara AZRB, 71, Persiaran Gurney - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E54B5E1",
          kunnr: null,
          kunn2: null,
          name1:
            "AHS Architects Sdn Bhd (formerly Arkitek Abu Hassan Salleh)  Penang (ACT)",
          name2: "",
          status: 4,
          street:
            "6, Jalan Arowana 3, Kawasan Perniagaan Arowana - PERMATANG PAUH",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E55077A",
          kunnr: null,
          kunn2: null,
          name1: "Ahyat & Associates (BCI)",
          name2: null,
          status: 1,
          street: null,
          created_from_source: "BCI",
        },
        {
          id: "B5E48F2E551D04",
          kunnr: null,
          kunn2: null,
          name1: "Aicos Management Corporation Sdn Bhd  Bahau (ACT)",
          name2: "",
          status: 4,
          street: "23/1, Pusat Perniagaan Angsana, Jalan Juasseh - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E552E10",
          kunnr: null,
          kunn2: null,
          name1: "AJC Planning Consultants Sdn Bhd (BCI)",
          name2: null,
          status: 1,
          street: null,
          created_from_source: "BCI",
        },
        {
          id: "B5E48F2E55A252",
          kunnr: null,
          kunn2: null,
          name1: "AJM Planning & Urban Design Group Sdn Bhd (APUDG) (BCI)",
          name2: null,
          status: 1,
          street: null,
          created_from_source: "BCI",
        },
        {
          id: "B5E48F2E55FDD1",
          kunnr: null,
          kunn2: null,
          name1: "Aki Media (ACT)",
          name2: "",
          status: 4,
          street:
            "Lot 9034, 1st &amp; 2nd Floor, Section 64, Lorong Sekama 3, Jalan Sekama, PO Box 437 - KUCHING",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E568F5B",
          kunnr: null,
          kunn2: null,
          name1: "Akipraktis (ACT)",
          name2: "",
          status: 4,
          street: "122, Jalan Sultan Azlan Shah - KUALA LUMPUR",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E575839",
          kunnr: null,
          kunn2: null,
          name1: "Akipro Chartered Architect (ACT)",
          name2: "",
          status: 4,
          street: "30, Jalan Cekor, Taman Sri Amar - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E57A8F7",
          kunnr: null,
          kunn2: null,
          name1: "Akitek Akiprima Sdn Bhd  Penang (ACT)",
          name2: "",
          status: 4,
          street: "29, Lorong Abu Siti - AIR ITAM",
          created_from_source: "ACT",
        },
        {
          id: "B5E48F2E57FAA1",
          kunnr: null,
          kunn2: null,
          name1: "Akitek Akiprima Sdn Bhd  Subang Jaya (ACT)",
          name2: "",
          status: 4,
          street:
            "E0104, Subang Square Business Park (East Wing), Jalan SS15/4G - AIR ITAM",
          created_from_source: "ACT",
        },
      ],
    },
  });
};

export const getDepotOptions = async () => {
  await delay();
  return Promise.resolve({
    succeeded: true,
    code: 0,
    message: "",
    objects: {
      depot: [
        {
          vtweg: "1A",
          depot_code: "1001",
          description: "1A - NP Shah Alam",
        },
        {
          vtweg: "1B",
          depot_code: "1002",
          description: "1B - NP Johor Bahru",
        },
      ],
    },
  });
};

export const getSalesrepOptionsByDepot = async (params = {}) => {
  let users = [];

  if (params?.vtweg === "1A")
    users = [
      {
        id: 139,
        emp_code: "TE263",
        vtweg: "1A",
        first_name: "ABDULLAH NAIM BIN MOHD MAZRI",
        last_name: "",
        emp_sap_id: "90000589",
        email: "naim@nipponpaint.com.my",
      },
      {
        id: 143,
        emp_code: "TE292",
        vtweg: "1A",
        first_name: "ANDREW BRIAN DE SOUZA",
        last_name: "",
        emp_sap_id: "90000796",
        email: "andrewdesouza@nipponpaint.com.my",
      },
      {
        id: 194,
        emp_code: "ME123",
        vtweg: "1A",
        first_name: "CHEN LEE SIONG",
        last_name: " ",
        emp_sap_id: "-",
        email: "chenleesiong@nipponpaint.com.my",
      },
      {
        id: 219,
        emp_code: "FO110",
        vtweg: "1A",
        first_name: "CHEW YJ",
        last_name: " ",
        emp_sap_id: "0",
        email: "yjchew@nipponpaint.com.my",
      },
      {
        id: 208,
        emp_code: "SE118",
        vtweg: "1A",
        first_name: "CHIN KEO SAN",
        last_name: "",
        emp_sap_id: "0",
        email: "kschin@nipponpaint.com.my",
      },
      {
        id: 137,
        emp_code: "TE254",
        vtweg: "1A",
        first_name: "CHOO CHERN KEN",
        last_name: "",
        emp_sap_id: "90000516",
        email: "chernken@nipponpaint.com.my",
      },
      {
        id: 195,
        emp_code: "SE258",
        vtweg: "1A",
        first_name: "CHOO KENG HOE",
        last_name: "-",
        emp_sap_id: "0",
        email: "bidev1@nipponpaint.com.my",
      },
      {
        id: 110,
        emp_code: "SE109",
        vtweg: "1A",
        first_name: "CHOW KWANG WAH",
        last_name: "",
        emp_sap_id: "90000036",
        email: "chowkw@nipponpaint.com.my",
      },
      {
        id: 246,
        emp_code: "SE276",
        vtweg: "1A",
        first_name: "FARAH NADIA MAHFUDZ",
        last_name: "",
        emp_sap_id: "90000921",
        email: "farahnadia@nipponpaint.com.my",
      },
      {
        id: 67,
        emp_code: "MK294",
        vtweg: "1A",
        first_name: "GABRIEL NG CHEE KAR",
        last_name: "",
        emp_sap_id: "0",
        email: "gabrielng@nipponpaint.com.my",
      },
      {
        id: 114,
        emp_code: "SE238",
        vtweg: "1A",
        first_name: "GAN KONG LIANG",
        last_name: "",
        emp_sap_id: "90000030",
        email: "klgan@nipponpaint.com.my",
      },
      {
        id: 140,
        emp_code: "TE285",
        vtweg: "1A",
        first_name: "GIDEON TAN",
        last_name: "",
        emp_sap_id: "0",
        email: "gideontan@nipponpaint.com.my",
      },
      {
        id: 216,
        emp_code: "HQ206",
        vtweg: "1A",
        first_name: "HATIM KURAISH",
        last_name: "",
        emp_sap_id: "0",
        email: "kuraish@nipponpaint.com.my",
      },
      {
        id: 218,
        emp_code: "ME118",
        vtweg: "1A",
        first_name: "HENG YING",
        last_name: " ",
        emp_sap_id: "0",
        email: "hengying@nipponpaint.com.my",
      },
      {
        id: 136,
        emp_code: "TE247",
        vtweg: "1A",
        first_name: "KHOR WEN JIE",
        last_name: "",
        emp_sap_id: "0",
        email: "wjkhor@nipponpaint.com.my",
      },
      {
        id: 223,
        emp_code: "LE114",
        vtweg: "1A",
        first_name: "KOO KIAN CHAI",
        last_name: "",
        emp_sap_id: "0",
        email: "kckoo@nipponpaint.com.my",
      },
      {
        id: 54,
        emp_code: "LE252",
        vtweg: "1A",
        first_name: "LAM WAI KEONG",
        last_name: "",
        emp_sap_id: "0",
        email: "wklam@nipponpaint.com.my",
      },
      {
        id: 224,
        emp_code: "LE116",
        vtweg: "1A",
        first_name: "LIM CHOO MAY",
        last_name: "",
        emp_sap_id: "0",
        email: "cmlim@nipponpaint.com.my",
      },
      {
        id: 222,
        emp_code: "LE294",
        vtweg: "1A",
        first_name: "LIM KIAT AN",
        last_name: " ",
        emp_sap_id: "0",
        email: "kalim@nipponpaint.com.my",
      },
      {
        id: 221,
        emp_code: "LE2034",
        vtweg: "1A",
        first_name: "LIM SEONG KEAT",
        last_name: " ",
        emp_sap_id: "0",
        email: "limsk@nipponpaint.com.my",
      },
      {
        id: 112,
        emp_code: "SE114",
        vtweg: "1A",
        first_name: "LOW CHEE KWONG",
        last_name: "",
        emp_sap_id: "90000299",
        email: "cklow@nipponpaint.com.my",
      },
      {
        id: 128,
        emp_code: "TE2003",
        vtweg: "1A",
        first_name: "LOW HEU YEE",
        last_name: "",
        emp_sap_id: "90000871",
        email: "lowhueyee@nipponpaint.com.my",
      },
      {
        id: 113,
        emp_code: "SE119",
        vtweg: "1A",
        first_name: "MOHD FAIZ BIN HEAMZAH",
        last_name: "",
        emp_sap_id: "90000872",
        email: "faizhamzah@nipponpaint.com.my",
      },
      {
        id: 198,
        emp_code: "SE274",
        vtweg: "1A",
        first_name: "MOHD RASID BIN HAMID",
        last_name: "-",
        emp_sap_id: "0",
        email: "bidev1@nipponpaint.com.my",
      },
      {
        id: 196,
        emp_code: "SE269",
        vtweg: "1A",
        first_name: "MUHAMMAD HASRULL FAZRIEQIN BIN SHADIKIN",
        last_name: "-",
        emp_sap_id: "0",
        email: "bidev1@nipponpaint.com.my",
      },
      {
        id: 132,
        emp_code: "TE236",
        vtweg: "1A",
        first_name: "NAWWAR FATHIAH BINTI MOHD FAUZI",
        last_name: "",
        emp_sap_id: "90000423",
        email: "nawwar@nipponpaint.com.my",
      },
      {
        id: 197,
        emp_code: "SE270",
        vtweg: "1A",
        first_name: "NOR REZIAM BIN AHMAD",
        last_name: "-",
        emp_sap_id: "0",
        email: "bidev1@nipponpaint.com.my",
      },
      {
        id: 109,
        emp_code: "SE104",
        vtweg: "1A",
        first_name: "ONG ENG KEONG",
        last_name: "",
        emp_sap_id: "0",
        email: "ekong@nipponpaint.com.my",
      },
      {
        id: 116,
        emp_code: "SE275",
        vtweg: "1A",
        first_name: "OOI CHIA CHEE",
        last_name: "",
        emp_sap_id: "90000456",
        email: "mikel@nipponpaint.com.my",
      },
      {
        id: 134,
        emp_code: "TE243",
        vtweg: "1A",
        first_name: "ROSLINA BINTI MAT HUSSIN",
        last_name: "",
        emp_sap_id: "90000561",
        email: "roslinahussin@nipponpaint.com.my",
      },
      {
        id: 138,
        emp_code: "TE255",
        vtweg: "1A",
        first_name: "RYAN WONG WEYSERN",
        last_name: "",
        emp_sap_id: "90000535",
        email: "weysern@nipponpaint.com.my",
      },
      {
        id: 142,
        emp_code: "TE287",
        vtweg: "1A",
        first_name: "SHATHIANANTHAN A/L SAMBAYAH",
        last_name: "",
        emp_sap_id: "90000794",
        email: "shathia@nipponpaint.com.my",
      },
      {
        id: 115,
        emp_code: "SE244",
        vtweg: "1A",
        first_name: "SONG SIEW SUM",
        last_name: "",
        emp_sap_id: "90000039",
        email: "jamessong@nipponpaint.com.my",
      },
      {
        id: 68,
        emp_code: "PS108",
        vtweg: "2A",
        first_name: "TAY SZE TUCK",
        last_name: "",
        emp_sap_id: "0",
        email: "sttay@nipponpaint.com.my",
      },
      {
        id: 252,
        emp_code: "TEST1",
        vtweg: "1A",
        first_name: "TESTING",
        last_name: "ACCOUNT",
        emp_sap_id: "0",
        email: "wjkhor@nipponpaint.com.my",
      },
      {
        id: 217,
        emp_code: "NMC_041",
        vtweg: "2A",
        first_name: "TOMOO ENOMOTO",
        last_name: "",
        emp_sap_id: "0",
        email: "tomoo.enomoto@nipsea.com.sg",
      },
      {
        id: 209,
        emp_code: "LE275",
        vtweg: "1A",
        first_name: "TONG LAI HOCK",
        last_name: "",
        emp_sap_id: "0",
        email: "lhtong@nipponpaint.com.my",
      },
      {
        id: 66,
        emp_code: "MK108",
        vtweg: "1A",
        first_name: "WONG MENG LEE",
        last_name: "",
        emp_sap_id: "0",
        email: "mlwong@nipponpaint.com.my",
      },
      {
        id: 61,
        emp_code: "ME105",
        vtweg: "2A",
        first_name: "YAW SENG HENG",
        last_name: "",
        emp_sap_id: "0",
        email: "shyaw@nipponpaint.com.my",
      },
      {
        id: 69,
        emp_code: "PS110",
        vtweg: "1A",
        first_name: "YEOH ENG HUEI",
        last_name: "",
        emp_sap_id: "90000515",
        email: "ehyeoh@nipponpaint.com.my",
      },
      {
        id: 111,
        emp_code: "SE111",
        vtweg: "1A",
        first_name: "YEW THIAM POH",
        last_name: "",
        emp_sap_id: "90000108",
        email: "tpyew@nipponpaint.com.my",
      },
    ];

  if (params?.vtweg === "1B")
    users = [
      {
        id: 206,
        emp_code: "8024",
        vtweg: "1B",
        first_name: "AZIZI AZHAR BIN MOKLAS",
        last_name: " ",
        emp_sap_id: "0",
        email: "azizi@nipponpaint.com.my",
      },
      {
        id: 219,
        emp_code: "FO110",
        vtweg: "1A",
        first_name: "CHEW YJ",
        last_name: " ",
        emp_sap_id: "0",
        email: "yjchew@nipponpaint.com.my",
      },
      {
        id: 208,
        emp_code: "SE118",
        vtweg: "1A",
        first_name: "CHIN KEO SAN",
        last_name: "",
        emp_sap_id: "0",
        email: "kschin@nipponpaint.com.my",
      },
      {
        id: 216,
        emp_code: "HQ206",
        vtweg: "1A",
        first_name: "HATIM KURAISH",
        last_name: "",
        emp_sap_id: "0",
        email: "kuraish@nipponpaint.com.my",
      },
      {
        id: 218,
        emp_code: "ME118",
        vtweg: "1A",
        first_name: "HENG YING",
        last_name: " ",
        emp_sap_id: "0",
        email: "hengying@nipponpaint.com.my",
      },
      {
        id: 1,
        emp_code: "8008",
        vtweg: "1B",
        first_name: "LEONG YOKE FATT",
        last_name: "",
        emp_sap_id: "90000141",
        email: "yfleong@nipponpaint.com.my",
      },
      {
        id: 205,
        emp_code: "8023",
        vtweg: "1B",
        first_name: "MOHAMAD NASHUHA BIN ABD WAHID",
        last_name: " ",
        emp_sap_id: "0",
        email: "mohdnashuha@nipponpaint.com.my",
      },
      {
        id: 68,
        emp_code: "PS108",
        vtweg: "2A",
        first_name: "TAY SZE TUCK",
        last_name: "",
        emp_sap_id: "0",
        email: "sttay@nipponpaint.com.my",
      },
      {
        id: 252,
        emp_code: "TEST1",
        vtweg: "1A",
        first_name: "TESTING",
        last_name: "ACCOUNT",
        emp_sap_id: "0",
        email: "wjkhor@nipponpaint.com.my",
      },
      {
        id: 217,
        emp_code: "NMC_041",
        vtweg: "2A",
        first_name: "TOMOO ENOMOTO",
        last_name: "",
        emp_sap_id: "0",
        email: "tomoo.enomoto@nipsea.com.sg",
      },
      {
        id: 61,
        emp_code: "ME105",
        vtweg: "2A",
        first_name: "YAW SENG HENG",
        last_name: "",
        emp_sap_id: "0",
        email: "shyaw@nipponpaint.com.my",
      },
    ];

  await delay();
  return Promise.resolve({
    succeeded: true,
    code: 0,
    message: "",
    objects: {
      users,
    },
  });
};
