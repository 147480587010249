import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  LOAD: "Load Country",
  UNLOAD: "Unload Country",
};

const initialAuthState = {
  country: null,
};

const { REACT_APP_IDENTIFIER, REACT_APP_ENV } = process.env;

export const reducer = persistReducer(
  {
    storage,
    key: `${REACT_APP_IDENTIFIER}_${REACT_APP_ENV}_country`,
    whitelist: ["country"],
  },
  (state = initialAuthState, action = {}) => {
    switch (action.type) {
      case actionTypes.LOAD: {
        const { country } = action.payload;

        return {
          country,
        };
      }

      case actionTypes.UNLOAD: {
        return {
          country: null,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  load: (country) => ({
    type: actionTypes.LOAD,
    payload: {
      country,
    },
  }),
  unload: () => ({ type: actionTypes.UNLOAD }),
};
