import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { actions } from "./modules/Auth/redux/authRedux";
import { MAIN_PAGE_NAVIGATION } from "./Navigation";
import ErrorPage from "./Common/ErrorPage";
import MainRoutes from "./Main/Routes";
// const MainRoutes = lazy(() => import("./Main/Routes"));

// This is where all the menu routes path is put
export default function MenuRoutes() {
  return (
    // <Suspense>
    <Switch>
      <Redirect exact from="/" to={MAIN_PAGE_NAVIGATION} />
      <Route path={MAIN_PAGE_NAVIGATION} component={MainRoutes} />
      <Route path="/error" component={ErrorPage} />
      <Route path="/not-authorise" component={ErrorPage} />

      <Redirect to="/error" />
    </Switch>
    // </Suspense>
  );
}
