import axios from "axios";
import {
  POST_LOGIN,
  POST_REFRESH_TOKEN,
  GET_SYSTEM_TOKEN,
  GET_ME,
} from "../../Endpoints";
import { getUniqueKeyCookieValue } from "../../../helpers/generalHelper";

export function postLogin(email, password, countryCode) {
  return axios.post(POST_LOGIN, {
    username: email,
    password,
    country_code: countryCode,
    device_info: `${navigator.userAgent}__${getUniqueKeyCookieValue()}`,
  });
}

export function refreshToken(refreshTokenValue) {
  return axios.post(POST_REFRESH_TOKEN, {
    refresh_token: refreshTokenValue,
    device_info: `${navigator.userAgent}__${getUniqueKeyCookieValue()}`,
  });
}

export function getUserByToken() {
  return axios.get(GET_ME);
}

export function generateSystemToken(systemCode) {
  return axios.get(GET_SYSTEM_TOKEN.replace("system_code", systemCode));
}

export default {};
