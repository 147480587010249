import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "@hookform/error-message";
import { FormHelperText } from "../../../_core/components";

// Documentation: https://react-hook-form.com/api/useformstate/errormessage
const ErrorHelperText = ({ name, children, ...props }) => (
  <ErrorMessage
    name={name}
    render={
      children ||
      (({ message }) => <FormHelperText error>{message}</FormHelperText>)
    }
    {...props}
  />
);

ErrorHelperText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  name: PropTypes.string.isRequired,
};

ErrorHelperText.defaultProps = {
  children: null,
};

export default ErrorHelperText;
