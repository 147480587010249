import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import {
  AnimatedSpinner,
  SelectOptions,
  FormControl,
} from "../../General/_core/components";
import { ErrorHelperText } from "../../General/components/ReactHookForm/components";

import { fetchCountry } from "../../General/services/optionsCRUD";

const CountrySelect = () => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
  } = useController({ name: "country_code" });

  const errorMessage = error?.message;

  const getCountry = async () => {
    setIsLoading(true);

    const resCountry = await fetchCountry();
    if (resCountry.succeeded) setCountryOptions(resCountry.objects.country);

    setIsLoading(false);
  };

  useEffect(() => {
    getCountry();

    return () => {
      getCountry();
    };
  }, []);

  return (
    <FormControl fullWidth error={!!errorMessage}>
      {isLoading ? (
        <AnimatedSpinner size="sm" />
      ) : (
        <>
          <SelectOptions
            {...fieldProps}
            inputRef={ref}
            fullWidth
            displayEmpty
            style={{
              color: "#666666",
              textAlign: "initial",
              marginBottom: "1.5em",
              fontSize: "1rem",
            }}
            keyConfig={{
              label: "description",
              value: "country_code",
            }}
            options={[
              {
                description: "Select Country",
                country_code: "",
              },
              ...countryOptions,
            ]}
          />
          <ErrorHelperText name="country_code" />
        </>
      )}
    </FormControl>
  );
};

CountrySelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

CountrySelect.defaultProps = {
  options: [],
  isLoading: false,
};
export default CountrySelect;
