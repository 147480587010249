import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as authRedux from "../redux/authRedux";

class Logout extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { logout } = this.props;
    logout();
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <Redirect to="/main" />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  authRedux.actions
)(Logout);
