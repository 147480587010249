import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AnimatedSpinner, Button } from "../../General/_core/components";

const SignInButton = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <Button
      variant="danger"
      type="submit"
      className="mb-7 w-100 btn-login"
      disabled={isSubmitting}
      size="lg"
    >
      {isSubmitting && (
        <AnimatedSpinner className="me-1" variant="light" size="sm" as="span" />
      )}
      {t("Sign In")}
    </Button>
  );
};

export default SignInButton;
