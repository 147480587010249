import React from "react";
import PropTypes from "prop-types";
// import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";

const VisibilityIconButton = ({ visible, onToggle }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onToggle}>
        {visible ? (
          <i className="far fa-eye" style={{ fontSize: "12px" }} />
        ) : (
          <i className="far fa-eye-slash" style={{ fontSize: "12px" }} />
        )}
      </IconButton>
    </InputAdornment>
  );
};

VisibilityIconButton.propTypes = {
  onToggle: PropTypes.func,
  visible: PropTypes.bool,
};

VisibilityIconButton.defaultProps = {
  onToggle: () => {},
  visible: false,
};

export default VisibilityIconButton;
