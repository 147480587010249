import React from "react";
import PropTypes from "prop-types";
import { FormControl as MFormControl } from "@mui/material";

const FormControl = ({ children, ...props }) => {
  return <MFormControl {...props}>{children}</MFormControl>;
};

FormControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

FormControl.defaultProps = {
  children: null,
  className: "",
};

export default FormControl;
