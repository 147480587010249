import React from "react";
import swal from "sweetalert";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { assetUrl } from "./generalHelper";

export const defaultDialogOpt = {
  title: "Sorry!",
  text: "Not Authorise",
  icon: assetUrl("/media/blobby/sad_blobby.svg"),
  className: "sw-dialog-box-custom",
};

export const swDialogBox = (opt) => swal(opt);

const ModalDialogBoxDefaultProps = {
  modalProps: {
    size: "lg",
    centered: false,
    backdropClassName: false,
    animation: false,
    scrollable: false,
  },
  headerProps: {
    onHide: () => {},
  },
};

export const ModalDialogBox = ({
  modalProps,
  headerProps,
  body,
  footer,
  title,
  showHeader,
  showFooter,
}) => {
  Object.assign(ModalDialogBoxDefaultProps.modalProps, modalProps);
  Object.assign(ModalDialogBoxDefaultProps.headerProps, headerProps);
  return (
    <Modal {...ModalDialogBoxDefaultProps.modalProps}>
      {showHeader ? (
        <Modal.Header {...ModalDialogBoxDefaultProps.headerProps}>
          {title}
        </Modal.Header>
      ) : null}
      <Modal.Body>{body}</Modal.Body>
      {showFooter ? <Modal.Footer>{footer}</Modal.Footer> : null}
    </Modal>
  );
};

ModalDialogBox.propTypes = {
  modalProps: PropTypes.instanceOf(Object),
  headerProps: PropTypes.instanceOf(Object),
  body: PropTypes.element,
  footer: PropTypes.element,
  title: PropTypes.element,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
};

ModalDialogBox.defaultProps = {
  modalProps: {
    size: "lg",
    centered: false,
    backdropClassName: false,
    animation: false,
    scrollable: false,
  },
  headerProps: {
    closeLabel: "close",
    closeButton: true,
    onHide: () => {},
  },
  showHeader: true,
  showFooter: true,
  body: <div>Modal Body</div>,
  footer: <div>Footer</div>,
  title: <div>Title</div>,
};
