import { Redirect, Switch, Route } from "react-router-dom";
import React from "react";
import Login from "./pages/Login";
// import AuthPageLayout from "./pages/AuthPage";

const Routes = () => (
  <Switch>
    <Route path="/auth/login" component={Login} />
    <Redirect from="/auth" exact to="/auth/login" />
    <Redirect to="/auth/login" />
  </Switch>
);

export default Routes;
