import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";
// import { getUserByToken } from "../services/AuthCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Refresh: "[Refresh] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetTimezone: "[Set Timezone] Action",
  LastLocation: "[Last Location]",
  ClearLastLocation: "[Remove Last Location]",
  IsRefreshing: "[Token Refresh]",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  selectedCountry: undefined,
  selectedTimezone: undefined,
  refreshToken: undefined,
  refreshExpiry: undefined,
  permission: undefined,
  lastLocation: undefined,
  allowedSystem: [],
  isRefreshing: false,
};

const { REACT_APP_IDENTIFIER, REACT_APP_ENV } = process.env;

export const reducer = persistReducer(
  {
    storage,
    key: `${REACT_APP_IDENTIFIER}_${REACT_APP_ENV}`,
    whitelist: [
      "user",
      "authToken",
      "selectedCountry",
      "selectedTimezone",
      "refreshToken",
      "refreshExpiry",
      "permission",
      "lastLocation",
      "allowedSystem",
    ],
  },
  (state = initialAuthState, action = {}) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {
          authToken,
          selectedCountry,
          refreshExpiry,
          refreshToken,
          permission,
          user,
          allowedSystem,
        } = action.payload;

        return {
          ...state,
          authToken,
          selectedCountry,
          refreshExpiry,
          refreshToken,
          permission,
          user,
          allowedSystem,
          lastLocation: state.lastLocation,
        };
      }

      case actionTypes.Refresh: {
        const { authToken, refreshToken, refreshExpiry } = action.payload;

        return {
          ...state,
          authToken,
          refreshToken,
          refreshExpiry,
        };
      }

      case actionTypes.Register: {
        const { authToken, selectedCountry } = action.payload;

        return { authToken, user: undefined, selectedCountry };
      }

      case actionTypes.Logout: {
        return {
          ...state,
          authToken: undefined,
          user: undefined,
          refreshToken: undefined,
          refreshExpiry: undefined,
          permission: undefined,
        };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetTimezone: {
        const { timezone } = action.payload;
        return { ...state, selectedTimezone: timezone };
      }

      case actionTypes.LastLocation: {
        return { ...state, lastLocation: action.payload.path };
      }

      case actionTypes.ClearLastLocation: {
        return { ...state, lastLocation: undefined };
      }

      case actionTypes.IsRefreshing: {
        return { ...state, isRefreshing: action.payload.is_refresh };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (
    authToken,
    selectedCountry,
    user,
    refreshToken,
    refreshExpiry,
    allowedSystem
  ) => ({
    type: actionTypes.Login,
    payload: {
      authToken,
      selectedCountry,
      user,
      refreshToken,
      refreshExpiry,
      allowedSystem,
    },
  }),
  refresh: (authToken, refreshToken, refreshExpiry) => ({
    type: actionTypes.Refresh,
    payload: { authToken, refreshToken, refreshExpiry },
  }),
  register: (authToken, selectedCountry) => ({
    type: actionTypes.Register,
    payload: { authToken, selectedCountry },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested, payload: {} }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setTimezone: (timezone) => ({
    type: actionTypes.SetTimezone,
    payload: { timezone },
  }),
  lastLocation: (path) => ({
    type: actionTypes.LastLocation,
    payload: { path },
  }),
  clearLastLocation: () => ({ type: actionTypes.ClearLastLocation }),
  isRefreshing: (bool) => ({
    type: actionTypes.IsRefreshing,
    payload: { is_refresh: bool },
  }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* logiSaga() {
  //   yield put(actions.requestUser());
  // });
  //
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {
  //     objects: { user },
  //   } = yield getUserByToken();
  //
  //   yield put(actions.fulfillUser(user));
  // });
}
