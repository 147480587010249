import { Switch, Route } from "react-router-dom";
import React from "react";
import { Main } from "./pages";
import { MAIN_PAGE_NAVIGATION } from "./Navigation";

const Routes = () => (
  <Switch>
    <Route path={MAIN_PAGE_NAVIGATION} component={Main} />
  </Switch>
);

export default Routes;
