/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Logout, AuthRoutes } from "./Auth";
import MenuRoutes from "./MenuRoutes";

export default function BeforeAuthRoutes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {/* <MenuRoutes /> */}

      {!isAuthorized ? (
        // Render auth page when user at `/auth` and not authorized.
        <Route>
          <AuthRoutes />
        </Route>
      ) : (
        // Otherwise redirect to root page (`/`)
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        // Redirect to `/auth` when user is not authorized
        <Redirect to="/auth/login" />
      ) : (
        <MenuRoutes />
      )}
    </Switch>
  );
}
