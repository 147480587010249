// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable"
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "@fortawesome/fontawesome-free/css/all.min.css";
import store, { persistor } from "./redux/store";
import "./index.css";
import App from "./app/App";
// import reportWebVitals from "./reportWebVitals";
import {
  // clearLocalStorage,
  setConsoleMessage,
  setUniqueKeyCookie,
  setupAxios,
} from "./helpers";
import i18nInit from "./i18n";

serviceWorkerRegistration.register();

const { PUBLIC_URL } = process.env;

setupAxios();
setConsoleMessage();
setUniqueKeyCookie();

// Need to initialise i118n instance before rendering the React App.
// This because the is conflict between metronic theme provider instance and initReactI18next instance.

const FullApp = () => (
  // <MetronicLayoutProvider>
  //     <MetronicSubheaderProvider>
  //         <MetronicSplashScreenProvider>
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  //         </MetronicSplashScreenProvider>
  //     </MetronicSubheaderProvider>
  // </MetronicLayoutProvider>
);

// eslint-disable-next-line react/no-render-return-value
i18nInit(() => ReactDOM.render(<FullApp />, document.getElementById("root")));

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
