import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { TextField } from "../../../../../_core/components";

// WARNING: This component must be wrap with <FormProvider/>
const DefaultField = ({ name, label, ...props }) => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
  } = useController({ name });

  const errorMessage = error?.message;

  return (
    <TextField
      {...fieldProps}
      inputRef={ref}
      label={label}
      error={!!errorMessage}
      helperText={!!errorMessage && errorMessage}
      {...props}
    />
  );
};

DefaultField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

DefaultField.defaultProps = {
  label: "",
};

export default DefaultField;
