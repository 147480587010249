import React from "react";
import { Spinner } from "react-bootstrap";

const AnimatedLoading = ({ ...props }) => (
  <Spinner animation="border" role="status" {...props}>
    <span className="visually-hidden">Loading...</span>
  </Spinner>
);

export default AnimatedLoading;
