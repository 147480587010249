import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { InputLabel } from "@mui/material";
import { SelectOptions, FormControl } from "../../../../../_core/components";
import ErrorHelperText from "../../ErrorHelperText";

const SelectField = ({ name, label, ...props }) => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
  } = useController({ name });

  const errorMessage = error?.message;

  const INPUT_LABEL_ID = `label-${name}`;

  return (
    <FormControl error={!!errorMessage}>
      <InputLabel id={INPUT_LABEL_ID}>{label}</InputLabel>
      <SelectOptions
        {...fieldProps}
        inputRef={ref}
        labelId={INPUT_LABEL_ID}
        label={label}
        {...props}
      />
      <ErrorHelperText name={name} />
    </FormControl>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

SelectField.defaultProps = {
  label: "",
};

export default SelectField;
