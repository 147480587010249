// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import HttpApi from "i18next-http-backend";
// import { getLanguage } from "./helpers/i18nHelper";
// import { GET_LOCALE_I18N } from "./app/Endpoints";

const i18nInit = (ReactDom) => ReactDom();
// getLanguage()
//   .then((lang) =>
//     i18n
//       .use(initReactI18next) // passes i18n down to react-i18next
//       .use(HttpApi)
//       .init({
//         debug: false,
//         lng: lang.selectedLang,
//         fallbackLng: "en",
//         keySeparator: false, // we do not use keys in form messages.welcome
//         interpolation: {
//           escapeValue: false, // react already safes from xss
//         },
//         backend: {
//           loadPath: process.env.REACT_APP_SERVICES_URL + GET_LOCALE_I18N,
//           parse: (data) => {
//             const proData = JSON.parse(data);
//             if (proData.succeeded) {
//               return proData.objects.lang;
//             }
//             return {};
//           },
//         },
//       })
//   )
//   .then(() => {
//     ReactDom();
//   });
export default i18nInit;
