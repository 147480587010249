import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextField } from "../../General/_core/components";

const UsernameField = () => {
  const { t } = useTranslation();
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
  } = useController({ name: "username" });

  const errorMessage = error?.message;

  return (
    <TextField
      {...fieldProps}
      inputRef={ref}
      label={t("Employee ID")}
      autoComplete="off"
      error={!!errorMessage}
      helperText={!!errorMessage && errorMessage}
      fullWidth
      style={{
        color: "#666666",
        border: "0px",
        marginBottom: "1.5em",
        fontSize: "13px",
      }}
    />
  );
};

export default UsernameField;
