import React from "react";
import PropTypes from "prop-types";
import { DefaultField, SelectField, AutocompleteField } from "./components";

/**
 * @requires
 * - This component must be wrap within the `<FormProvider/>`
 * - `name` property
 *
 * @description
 * - All The predefined components in here will get your respective field's properties as props, excluding `validationSchema` & `initialValues`
 * - Only `render` will have access to all property, excluding `validationSchema`
 *
 * @see Check out the propTypes `type` to render predefined component
 *
 * @returns {(React.Node|Element|null)} `<TextField/>`, `<SelectOptions/>`, `<Autcomplete/>` or custom component using `render` in your configuration
 */

const Field = ({ type, render, label, name, fieldProps, ...fieldConfig }) => {
  const baseProps = {
    label,
    name,
  };
  const defaultFieldProps = {
    ...baseProps,
    ...fieldProps,
  };

  if (!name || type === "hidden") return null;

  if (typeof render === "function" || type === "custom") {
    if (type && !render) {
      console.error("Fields: You need a `render` property to show your field");
      return null;
    }

    return render({ ...defaultFieldProps, ...fieldConfig });
  }

  if (type === "autocomplete") {
    return <AutocompleteField {...defaultFieldProps} />;
  }
  if (type === "select") return <SelectField {...defaultFieldProps} />;

  return <DefaultField {...defaultFieldProps} />;
};

Field.propTypes = {
  type: PropTypes.oneOf(["custom", "select", "autocomplete", "hidden"]),
  render: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldProps: PropTypes.object,
};

Field.defaultProps = {
  type: null,
  render: null,
  label: "",
  fieldProps: {},
};

export default Field;
