import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { defaultDialogOpt } from "../../../../../../helpers/dialogHelper";

const Fallback = ({ error, resetErrorBoundary, ...props }) => {
  const { REACT_APP_SUPPORT_WHATSAPP_CONTACT } = process.env;
  const { t } = useTranslation();
  const crashedMessage = `Please retry again, if error persist please click "Report"`;
  const whatsappUrl = REACT_APP_SUPPORT_WHATSAPP_CONTACT
    ? `https://wa.me/${REACT_APP_SUPPORT_WHATSAPP_CONTACT}`
    : "";

  const contactSupport = () => {
    if (whatsappUrl) {
      console.log("contactSupport");
      window.open(whatsappUrl);
    }
  };

  const option = {
    ...defaultDialogOpt,
    title: t("Something went wrong"),
    text: crashedMessage,
    buttons: [
      {
        text: t("Report"),
        value: null,
        visible: true,
        closeModal: false,
      },
      {
        text: t("Refresh"),
        value: true,
        visible: true,
        closeModal: true,
      },
    ],
    dangerMode: true,
    ...props,
  };

  const alertDialog = async () => {
    let currentDialog = null;
    currentDialog = await swal(option);

    const isRefreshClicked = currentDialog;
    if (isRefreshClicked) resetErrorBoundary();
    else {
      alertDialog();
      contactSupport();
    }

    swal.stopLoading();
  };

  alertDialog();
};

Fallback.propTypes = {};

export default Fallback;
