// Locale
export const GET_LOCALE_I18N = "/common/locale/{{lng}}";

// Auth
export * from "./Auth/Endpoints";

// Services
export const POST_REFRESH_TOKEN = "/auth/refresh";
export const GET_ME = "/auth/me";
export const GET_SYSTEM_TOKEN = "/auth/link/{system_code}";
