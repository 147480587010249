import React from "react";
import PropTypes from "prop-types";
import { Autocomplete as MAutocomplete, InputAdornment } from "@mui/material";

import TextField from "./TextField";
import AnimatedSpinner from "./AnimatedSpinner";

const Autocomplete = ({ options, textFieldProps, loading, ...props }) => (
  <MAutocomplete
    options={options}
    loading={loading}
    renderInput={(params) => (
      <TextField
        {...params}
        {...textFieldProps}
        InputProps={{
          ...params.InputProps,
          endAdornment: loading ? (
            <InputAdornment position="end">
              <AnimatedSpinner size="sm" />
            </InputAdornment>
          ) : (
            params.InputProps.endAdornment
          ),
          ...textFieldProps?.InputProps,
        }}
      />
    )}
    {...props}
  />
);

Autocomplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  textFieldProps: PropTypes.object,
  loading: PropTypes.bool,
};

Autocomplete.defaultProps = {
  options: [],
  textFieldProps: {},
  loading: true,
};

export default Autocomplete;
