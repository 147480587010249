import React from "react";
import PropTypes from "prop-types";
import Field from "../Field";

const FieldBuilders = ({ fields }) => {
  return fields.map((field) => <Field key={field.name} {...field} />);
};

FieldBuilders.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.arrayOf(PropTypes.object),
};

FieldBuilders.defaultProps = {
  fields: [],
};

export default FieldBuilders;
