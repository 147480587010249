import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { Autocomplete } from "../../../../../_core/components";

const AutocompleteField = ({ name, label, textFieldProps, ...props }) => {
  const {
    field: { ref, onChange, ...fieldProps },
    fieldState: { error },
  } = useController({ name });

  const errorMessage = error?.message;

  return (
    <Autocomplete
      {...fieldProps}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      textFieldProps={{
        inputRef: ref,
        error: !!errorMessage,
        helperText: !!errorMessage && errorMessage,
        ...textFieldProps,
      }}
      {...props}
    />
  );
};

AutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  textFieldProps: PropTypes.object,
};

AutocompleteField.defaultProps = {
  label: "",
  textFieldProps: {},
};

export default AutocompleteField;
